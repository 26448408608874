.nodeTitle {
  font-family: "Inter";
  margin: 0;
  padding: 0;
  background: transparent;
  border: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  box-sizing: border-box;
  outline: none;
  text-align: center;
  font-size: 10px;
  position: relative;
  bottom: 2px;
  color: #919ead;
}

.nodeTitle:focus {
  outline: none;
}
