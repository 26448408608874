.swiper-button-next svg {
  width: 14px;
  height: 24px;
  object-fit: contain;
  transform-origin: center center;
  position: absolute;
  left: -12px;
  background-color: #000;
  padding: 5px 10px;
  border-radius: 50;
}
