.popoverOptionDiv:hover {
    transition: 'background-color 0.3s';
    background-color: rgba(0, 0, 0, 0.02);
}
/* 
.MuiPaper-root .MuiPaper-elevation .MuiPaper-rounded .MuiPaper-elevation8 .MuiPopover-paper .css-3bmhjh-MuiPaper-root-MuiPopover-paper {
    border-radius: 15px;

} */

.css-3bmhjh-MuiPaper-root-MuiPopover-paper {
    border-radius: 15px;
}
/* 
.css-3bmhjh-MuiPaper-root-MuiPopover-paper {
    border-radius: 15px;
} */