.typing-animation {
    display: inline-flex;
    align-items: flex-start;
    background: #ffffff;
    border-radius: 50px;
}

.typing-animation .type-dot {
    height: 6px;
    width: 6px;
    opacity: 0.7;
    margin: 0.3px;
    border-radius: 50%;
    margin-right: 4px;
    background: #272727;
    animation: animateDots 1.8s var(--delay) ease-in-out infinite;
}

@keyframes animateDots {
    0%, 44% {
        transform: translateY(0px);
    }
    28% {
        opacity: 0.4;
        transform: translateY(0px)
    }
    44% {
        opacity: 0.2;
        transform: translateY(0px)
    }
}