.addButton {
    visibility: hidden;
}

.nodeContainer:hover .addButton {
    visibility: visible;
    transition: opacity 0.3s;
}

/* .startButton:hover {
    cursor: not-allowed;
} */

.failureNode:hover {
    cursor: not-allowed
}

.successNode:hover {
    cursor: not-allowed
}


