/* svg {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
}
.container {
  display: inline-block;
  position: relative;
  width: 100vh;
  padding-bottom: 100%;
  vertical-align: left;
  overflow: hidden;
  flex: 1;
  flex-grow: 1;
} */

.background {
  color: #999999;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -100;
}

.custom-dots {
  margin-top: 38px;
}
