#editableDiv {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Inter";
  height: 45px;
  border-radius: 4px;
  max-width: 360px;
  min-width: 1ch;
  overflow: hidden !important;
  padding: 2px 6px;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  z-index: 1;
  margin: 10px;
  border: 2px solid transparent;
  transition: 0.3 ease;
}

/* #editableDiv:hover {
  border: 2px solid #59687b;
  transition: 0.3 ease;
} */

#title-input {
  font-family: "Inter";
  color: #fff;
  border: none;
  outline: none;
  height: 45px;
  min-width: 1ch;
  font-weight: 600;
  font-size: 24px;
  margin-left: 10px;
  background-color: transparent;
}

#title-light {
  font-family: "Inter";
  color: #fff;
  border: none;
  outline: none;
  height: 45px;
  min-width: 1ch;
  font-weight: 600;
  font-size: 24px;
  margin-left: 10px;
  background-color: transparent;
  color: #000000;
}

.sidebar-container {
  position: absolute;
  top: 0px;
  right: 0px;
  transition: background-color 0.3s ease;
  z-index: 1
}

.sidebar-bot {
  position: absolute;
  top: 40px;
  right: 0px;
  z-index: 1
}

.blur-content {
  position: relative;
  width: 100%;
  height: 100vh;
  transition: background-color 2s ease;
}

.blur-content::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: background-color 2s ease;
  background-color: rgba(0, 0, 0, 0.5); 
  z-index: 1; 
}

.main-container {
  position: relative;
  overflow: hidden;
}

.react-flow-div {
  width: 100%;
  height: 100vh;
  background-color: #22304a;
  transition: filter 0.3s ease;
}
