.dsg-cell-header,
.dsg-cell-gutter {
  white-space: nowrap;
  width: auto;
}

.error-cell {
  border-color: red;
  color: red;
}
.height-cell {
  min-height: 150px;
  /* position: relative; */
  align-items: center;
}
